@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);
/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #2b5d4c;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(75deg);
  z-index: -2;
  cursor: "pointer";
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #e31a1a;
  animation-delay: 1.1s;
  cursor: "pointer";
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.carousel-image {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.carousel-container {
  position: relative;
  margin: auto;
}

.carousel-item {
  width: 100%;
  height: 100%;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
          user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.carousel-caption-bottom {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.carousel-caption-center {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  bottom: 50%;
  top: 45%;
  width: 100%;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}

.carousel-caption-top {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  top: 8px;
  width: 100%;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.dots {
  margin-top: 10px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.slide-number {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
.pause-icon {
  position: absolute;
  /* top: 38%; */
  left: 15px;
  bottom: 2px;
  /* right: 50%; */
  opacity: 1;
}

.pause {
  -webkit-animation-name: pause;
  -webkit-animation-duration: 0.5s;
  animation-name: pause;
  animation-duration: 0.5s;
}

.bar {
  width: 100%;
  background-color: #ddd;
  position: absolute;
    bottom: 0px;
    border-radius: 0 0 10px;
}

.progress {
  width: 1%;
  height: 5px;
  background-color: #042baa;
}

.thumbnails {
  display: flex;
  margin-top: 10px;
  align-items: center;
  overflow: scroll;
}
.thumbnails::-webkit-scrollbar {
  display: none;
}
.thumbnail {
  margin: 0 5px;
}
.active-thumbnail {
  border: #476ff1 solid 3px;
}
@keyframes pause {
  0% {opacity: .2; }
  90% {opacity: 1; }
  
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@media only screen and (max-width: 500px) {
  .prev,
  .next,
  .carousel-caption-bottom, 
  .carousel-caption-center, 
  .carousel-caption-top, 
  .slide-number 
  {
    font-size: 20px !important;
  }
  .dot {
    height: 4px;
    width: 4px;
  }
  .carousel-container{
    max-height: 250px !important;
  }
  .thumbnail {
    max-width: 70px;
  }
  .carousel-caption-bottom{
    bottom: 25px;
  }
}
